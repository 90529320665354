@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.select {
		@apply px-1 py-2 mr-3 outline-none bg-gray-300 rounded-sm hover:bg-gray-400;
	}

	.option {
		@apply bg-gray-200 outline-none my-6 border-none py-3;
	}
}